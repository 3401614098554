import React from 'react';
import { IconProps } from './interface';

export const Instagram: React.FC<IconProps> = ({
  fill = 'fill-[#1A2223]',
  className,
  size,
}) => {
  return (
    <svg className={`${className} ${size}`} viewBox="0 0 24 24" fill="none">
      <path
        d="M7.99841 2.99072C5.23741 2.99072 2.99841 5.22972 2.99841 7.99072V15.9907C2.99841 18.7517 5.23741 20.9907 7.99841 20.9907H15.9984C18.7594 20.9907 20.9984 18.7517 20.9984 15.9907V7.99072C20.9984 5.22972 18.7594 2.99072 15.9984 2.99072H7.99841ZM7.99841 4.99072H15.9984C17.6554 4.99072 18.9984 6.33372 18.9984 7.99072V15.9907C18.9984 17.6477 17.6554 18.9907 15.9984 18.9907H7.99841C6.34141 18.9907 4.99841 17.6477 4.99841 15.9907V7.99072C4.99841 6.33372 6.34141 4.99072 7.99841 4.99072ZM15.9984 6.99072C15.4464 6.99072 14.9984 7.43872 14.9984 7.99072C14.9984 8.54272 15.4464 8.99072 15.9984 8.99072C16.5504 8.99072 16.9984 8.54272 16.9984 7.99072C16.9984 7.43872 16.5504 6.99072 15.9984 6.99072ZM11.9984 7.99072C9.78941 7.99072 7.99841 9.78172 7.99841 11.9907C7.99841 14.1997 9.78941 15.9907 11.9984 15.9907C14.2074 15.9907 15.9984 14.1997 15.9984 11.9907C15.9984 9.78172 14.2074 7.99072 11.9984 7.99072ZM11.9984 9.99072C13.1034 9.99072 13.9984 10.8857 13.9984 11.9907C13.9984 13.0957 13.1034 13.9907 11.9984 13.9907C10.8934 13.9907 9.99841 13.0957 9.99841 11.9907C9.99841 10.8857 10.8934 9.99072 11.9984 9.99072Z"
        className={fill}
      />
    </svg>
  );
};
