import React from 'react';
import { IconProps } from './interface';

export const Youtube: React.FC<IconProps> = ({
  fill = 'fill-[#1A2223]',
  className,
  size,
}) => {
  return (
    <svg className={`${className} ${size}`} viewBox="0 0 24 24" fill="none">
      <path
        d="M5.9989 4.88293C3.7898 4.88293 1.9989 6.67393 1.9989 8.88293V14.8829C1.9989 17.0919 3.7898 18.8829 5.9989 18.8829H17.9989C20.208 18.8829 21.9989 17.0919 21.9989 14.8829V8.88293C21.9989 6.67393 20.208 4.88293 17.9989 4.88293H5.9989ZM10.7132 8.88293C10.8375 8.88293 10.9462 8.91793 11.048 8.97693C11.1059 9.00993 14.6688 11.2089 14.798 11.3439C14.9277 11.4799 14.9989 11.6749 14.9989 11.8829C14.9989 12.0149 14.9663 12.1269 14.9096 12.2349C14.8634 12.3219 14.8054 12.4069 14.731 12.4689C14.6094 12.5699 11.0255 14.8039 10.9364 14.8359C10.8636 14.8619 10.7944 14.8829 10.7132 14.8829C10.5347 14.8829 10.3696 14.8089 10.2444 14.6949C10.0929 14.5579 9.9989 14.3599 9.9989 14.1329C9.9989 13.9279 9.9989 9.83693 9.9989 9.63293C9.9989 9.21893 10.3187 8.88293 10.7132 8.88293Z"
        className={fill}
      />
    </svg>
  );
};
