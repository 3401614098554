'use client';
import { Link } from '@remix-run/react';
import React from 'react';
import { X } from '~/components/icons/X';
import { getAsset } from '~/lib/asset';
import { Facebook } from '../../icons/Facebook';
import { Instagram } from '../../icons/Instagram';
import { Line } from '../../icons/Line';
import { Linkedin } from '../../icons/Linkedin';
import { Medium } from '../../icons/Medium';
import { Youtube } from '../../icons/Youtube';
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINE_URL,
  LINKEDIN_URL,
  MEDIUM_URL,
  X_URL,
  YOUTUBE_URL,
} from './constant';

const Footer: React.FC = () => {
  return (
    <footer className="mx-auto w-full text-light dark:text-dark dark:fill-neutral-100 fill-bg-component-dark-card bg-component-light-card dark:bg-component-dark-card py-12 sm:px-20 border-t-[2px] border-component-light-border dark:border-component-dark-border relative z-50">
      <div className="flex flex-col sm:flex-row items-center gap-4 justify-between max-sm:mb-4">
        <div className="flex flex-col gap-5 items-start justify-end">
          <Link to="/" className="relative h-12 aspect-[4]">
            <img
              src={getAsset('/brands/logo-text-light.png')}
              alt="text logo"
              sizes=""
              className="object-contain dark:hidden"
            />
            <img
              src={getAsset('/brands/logo-text-dark.png')}
              alt="text logo"
              sizes=""
              className="object-contain hidden dark:block"
            />
          </Link>
        </div>
        <div className="flex gap-5 items-center">
          <a
            href={FACEBOOK_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="duration-300 hover:scale-110"
          >
            <Facebook size="w-6 h-6" fill="fill-current" />
          </a>
          <a
            href={X_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="duration-300 hover:scale-110"
          >
            <X size="w-5 h-5" fill="fill-current" />
          </a>
          <a
            href={YOUTUBE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="duration-300 hover:scale-110"
          >
            <Youtube size="w-6 h-6" fill="fill-current" />
          </a>
          <a
            href={INSTAGRAM_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="duration-300 hover:scale-110"
          >
            <Instagram size="w-6 h-6" fill="fill-current" />
          </a>
          <a
            href={LINKEDIN_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="duration-300 hover:scale-110"
          >
            <Linkedin size="w-6 h-6" fill="fill-current" />
          </a>
          <a
            href={LINE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="duration-300 hover:scale-110"
          >
            <Line size="w-6 h-6" fill="fill-current" />
          </a>
          <a
            href={MEDIUM_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="duration-300 hover:scale-110"
          >
            <Medium size="w-6 h-6" fill="fill-current" />
          </a>
        </div>
      </div>
      <div className="flex flex-col max-sm:items-center">
        <span className="text-base inline font-bold font-ubuntu">
          © 2025 COMPFEST. All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
