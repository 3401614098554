import React from 'react';
import { IconProps } from './interface';

export const Linkedin: React.FC<IconProps> = ({
  fill = 'fill-[#1A2223]',
  className,
  size,
}) => {
  return (
    <svg className={`${size} ${className}`} viewBox="0 0 24 24" fill="none">
      <path
        d="M4.98304 7.19698C6.19144 7.19698 7.17104 6.21738 7.17104 5.00898C7.17104 3.80058 6.19144 2.82098 4.98304 2.82098C3.77464 2.82098 2.79504 3.80058 2.79504 5.00898C2.79504 6.21738 3.77464 7.19698 4.98304 7.19698Z"
        className={fill}
      />
      <path
        d="M9.23697 8.85499V20.994H13.006V14.991C13.006 13.407 13.304 11.873 15.268 11.873C17.205 11.873 17.229 13.684 17.229 15.091V20.995H21V14.338C21 11.068 20.296 8.55499 16.474 8.55499C14.639 8.55499 13.409 9.56199 12.906 10.515H12.855V8.85499H9.23697ZM3.09497 8.85499H6.86997V20.994H3.09497V8.85499Z"
        className={fill}
      />
    </svg>
  );
};
