import React from 'react';
import { IconProps } from './interface';

export const Medium: React.FC<IconProps> = ({
  fill = 'fill-[#1A2223]',
  className,
  size,
}) => {
  return (
    <svg className={`${className} ${size}`} viewBox="0 0 24 24" fill="none">
      <path
        d="M13.5374 12.0001C13.5374 15.728 10.5069 18.75 6.76884 18.75C3.03073 18.75 0 15.7273 0 12.0001C0 8.27293 3.0305 5.25 6.76884 5.25C10.5072 5.25 13.5374 8.27224 13.5374 12.0001ZM20.9628 12.0001C20.9628 15.5091 19.4476 18.3548 17.5784 18.3548C15.7092 18.3548 14.194 15.5091 14.194 12.0001C14.194 8.49113 15.709 5.6454 17.5782 5.6454C19.4474 5.6454 20.9628 8.49022 20.9628 12.0001ZM24 12.0001C24 15.1433 23.4672 17.6929 22.8097 17.6929C22.1522 17.6929 21.6196 15.144 21.6196 12.0001C21.6196 8.85624 22.1524 6.3073 22.8097 6.3073C23.4669 6.3073 24 8.85601 24 12.0001Z"
        className={fill}
      />
    </svg>
  );
};
